import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Mousewheel,
  EffectCreative,
} from 'swiper/modules';

import './SwiperWrapper.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-creative';

import useWindowSize from '../../assets/hooks/useWindowSize';

export default function SwiperWrapper({
  isColorBlock,
  slides,
  id,
  setIsEnd,
  isEnd,
  classNameUnic,
  setSwiper,
  setActiveIndex,
  activeIndex,
}) {
  const { width } = useWindowSize();

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);

    if (swiper.activeIndex === slides.length - 1) {
      setIsEnd(true);
    } else if (swiper.activeIndex === 0) {
      setIsEnd(false);
    }
  };

  // Single-slide-per-scroll mouse wheel config
  const mousewheelConfig = {
    forceToAxis: true,
    sensitivity: 0.7,
    releaseOnEdges: false,
    thresholdDelta: 1,
  };

  // Tweak transition speed in ms
  const transitionSpeed = 600;

  return (
    <div
      className={`swiper-element ${classNameUnic}`}
      style={{
        '--swiper-theme-color': isColorBlock
          ? 'var(--swiper-secondary-color)'
          : 'var(--swiper-main-color)',
        '--swiper-pagination-bullet-inactive-color': isColorBlock
          ? 'var(--color-block-tertiary)'
          : 'var(--neutral-tertiary)',
        '--swiper-pagination-bottom': width > 900 ? '40px' : '46px',
        '--swiper-pagination-bullet-size': width > 900 ? '12px' : '8px',
        '--swiper-pagination-bullet-horizontal-gap': '8px',
        '--swiper-pagination-bullet-inactive-opacity': '1',
      }}
    >
      <Swiper
        onSwiper={setSwiper}
        className="swiper-element__swiper"
        modules={[Mousewheel, Navigation, Pagination, EffectCreative]}
        direction="vertical"
        slidesPerView={1}
        slidesPerGroup={1}
        longSwipes={false}
        mousewheel={mousewheelConfig}
        speed={transitionSpeed}
        resistanceRatio={1}
        onSlideChange={handleSlideChange}
        effect="creative"
        creativeEffect={{
          // Minimal vertical "slide-in" animation
          prev: {
            translate: [0, '-100%', 0], // Slide previous slide up out of view
          },
          next: {
            translate: [0, '100%', 0],  // Slide next slide in from bottom
          },
        }}
      >
        {slides?.map((slide, i) => (
          <SwiperSlide key={i}>{slide}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
